import Repository from "./Repository";

const resource = "/patientreviews";
export default {

    get() {
        return Repository.get(`${resource}`);
    },

    statistics() {
        return Repository.get(`${resource}/statistics`);
    }
};
