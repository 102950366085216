<template>
    <div class="reviews">


        <ReviewCarousel />
    </div>
</template>

<script>
    // @ is an alias to /src
    import ReviewCarousel from '@/components/reviews/ReviewCarousel.vue'


    export default {
        name: 'reviews',
        data() {
            return {
                header: 'Reviews',
                subHeader: 'See what some of our wonderful patients have said about us...'
            };
        },
        components: {
            ReviewCarousel
        }
        ,
        title: 'Prodigy Dental Arts - Reviews'
    }
</script>
