//src/components/Navbar.vue
<template>
    <div v-if="!isLoading">

        <b-container fluid>
            <b-row style="background-color:#AAAAAA;padding-top:2px;padding-bottom:2px;" class="menu-phone">


                <b-col cols="8">


                    <a href="tel:+15097037397">(509) 703-7397 (Office)</a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <br class="menu-phone-seperator" />
                    <!--<span class="menu-phone-seperator"><br /></span>-->
                    <a href="tel:+15098288339">(509) 828-8339 (Text/Voice)</a>


                </b-col>
                <!--<b-col cols="2">

<open-closed v-bind:officeId="1" />


                </b-col>-->
                <b-col cols="4">
                    <div class="social-links-wrapper">
                        <a href="https://www.facebook.com/prodigydentalarts/" target="blank">  <b-icon-facebook class="social-links facebook"></b-icon-facebook></a>
                        <a href="https://www.instagram.com/prodigydentalarts" target="blank"> <b-icon-instagram class="social-links instagram"></b-icon-instagram></a>
                        <a href="https://www.linkedin.com/in/nadia-grishin-b8004280/" target="blank"><b-icon-linkedin class="social-links linkedIn"></b-icon-linkedin></a>
                    </div>
                </b-col>
            </b-row>
            <b-row style="background-color: #f8f9fa !important;">

                <b-col style="overflow:visible" >
                    <b-navbar-brand href="/">
                        <b-img :src="require('@/assets/images/logo-new.png')" alt="Prodigy Dental Arts" />
                    </b-navbar-brand>
                </b-col>
                <b-col style="background-color:#f8f9fa !important;">
                    <b-navbar toggleable="lg" type="light" variant="light" class="menu">
                        <b-container>


                            <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>
                            <b-collapse id="nav-collapse" is-nav align=right>
                                <!-- Right aligned nav items -->
                                <b-navbar-nav class="mt-auto p-2">
                                    <b-nav-item href="/">HOME</b-nav-item>

                                    <!-- About US -->
                                    <b-nav-item-dropdown text="ABOUT US" right>
                                        <b-dropdown-item href="/about/dr-grishin">MEET DR. GRISHIN</b-dropdown-item>
                                        <!--<b-dropdown-item href="/about/our-team">MEET OUR TEAM</b-dropdown-item>-->
                                    </b-nav-item-dropdown>

                                    <!-- Services -->
                                    <b-nav-item-dropdown text="SERVICES" right>
                                        <b-dropdown-item href="/services/preventive-care">PREVENTIVE CARE</b-dropdown-item>
                                        <b-dropdown-item href="/services/laser-dentistry">LASER DENTISTRY</b-dropdown-item>
                                        <b-dropdown-item href="/services/cosmetics">COSMETICS</b-dropdown-item>
                                        <b-dropdown-item href="/services/clear-braces">CLEAR BRACES</b-dropdown-item>
                                        <b-dropdown-item href="/services/implants">IMPLANTS</b-dropdown-item>
                                        <b-dropdown-item href="/services/extractions">EXTRACTIONS</b-dropdown-item>
                                        <b-dropdown-item href="/services/root-canal-therapy">ROOT CANAL THERAPY</b-dropdown-item>
                                        <b-dropdown-item href="/services/full-mouth-rehabilitations">FULL MOUTH REHABILITATIONS</b-dropdown-item>
                                        <b-dropdown-item href="/services/kids-dentistry">KID'S DENTISTRY</b-dropdown-item>
                                    </b-nav-item-dropdown>

                                    <b-nav-item href="/contact-us">CONTACT US</b-nav-item>
                                    <b-nav-item href="/reviews">REVIEWS</b-nav-item>

                                </b-navbar-nav>

                            </b-collapse>

                        </b-container>
                    </b-navbar>
                </b-col>
            </b-row>


        </b-container>
    </div>
</template>

<style>
    .menu {
        padding-left: 0%;
        padding-right: 2% !important;
       vertical-align:bottom;
    }

    


        .menu-phone a {
            color: black;
        }

        .menu-phone a:first-child {
            padding-right:5px;
        }

            .menu-phone a:hover {
                color: white;
            }


    .social-links {
        width: 25px;
        height: 25px;
        color: black;
        /*color: white;*/
        
    }

        .social-links:hover {
            color: white;
        }

    @media only screen and (min-width:768px) {
        .social-links-wrapper {
            text-align: right;
            margin-left: 4px;
        }

        .menu-phone-seperator {
            display: block;
        }

        .menu-phone {
            padding-left: 5vw;
        }
    }
    @media only screen and (min-width:992px) {
        .social-links-wrapper {
            text-align: right;
            margin-left: 4px;
        }
        .menu-phone-seperator {
            display: block;
        }
        .menu-phone {
            padding-left: 25vw;
        }
    }

    @media only screen and (min-width:1200px) {
        .social-links-wrapper {
            text-align: right;
            margin-left: 4px;
        }

        .facebook {
            margin: 5px 0px 0px 0px;
        }

        .menu-phone-seperator {
            display: none;
        }
        .menu-phone {
            padding-left: 25vw;
        }
    }

    /*@media only screen and (min-width:1081px) {
        .social-links-wrapper {
            text-align: right;*/
           /* margin-left: 11%;*/
        /*}

        .facebook {
            margin: 0px 0px 0px 20px;
        }

        .menu-phone-seperator {
            display: none;
        }

        .menu-phone {
            padding-left: 25vw;
        }
    }*/

    .instagram {
        padding: 0px 2px 0px 2px;
        margin: 0px 5px 0px 5px;
    }

    .linkedIn {
        padding-left: 2px;
    }
</style>

<script>
    /*import OpenClosed from './offices/OpenClosed.vue'*/
    import { BIconFacebook, BIconInstagram, BIconLinkedin } from 'bootstrap-vue'
    export default {
        data() {
            return {
                isLoading: false,
                logoResponse: null,
            }
        },
        created() {
            this.fetch()
        },
        components: { BIconFacebook, BIconInstagram, BIconLinkedin },
        methods: {
            async fetch() {
                this.isLoading = true
                this.isLoading = false
            }
        }
    }
</script>
