import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DrGrishin from '../views/about/DrGrishin.vue'
import OurTeam from '../views/about/OurTeam.vue'
import PreventiveCare from '../views/services/PreventiveCare.vue'
import LaserDentistry from '../views/services/LaserDentistry.vue'
import Cosmetics from '../views/services/Cosmetics.vue'
import ClearBraces from '../views/services/ClearBraces.vue'
import Implants from '../views/services/Implants.vue'
import Extractions from '../views/services/Extractions.vue'
import RootCanalTherapy from '../views/services/RootCanalTherapy.vue'
import KidsDentistry from '../views/services/KidsDentistry.vue'
import FullMouthRehabilitations from '../views/services/FullMouthRehabilitations.vue'
import ContactUs from '../views/ContactUs.vue'
import ContactUsThankYou from '../views/ContactUsThankYou.vue'
import Reviews from '../views/Reviews.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about/dr-grishin',
        name: 'DrGrishin',
        component: DrGrishin
    },
    {
        path: '/about/our-team',
        name: 'OurTeam',
        component: OurTeam
    },
    {
        path: '/services/preventive-care',
        name: 'PreventiveCare',
        component: PreventiveCare
    },
    {
        path: '/services/laser-dentistry',
        name: 'LaserDentistry',
        component: LaserDentistry
    },
    {
        path: '/services/cosmetics',
        name: 'Cosmetics',
        component: Cosmetics
    },
    {
        path: '/services/clear-braces',
        name: 'ClearBraces',
        component: ClearBraces
    },
    {
        path: '/services/implants',
        name: 'Implants',
        component: Implants
    },
    {
        path: '/services/extractions',
        name: 'Extractions',
        component: Extractions
    },
    {
        path: '/services/root-canal-therapy',
        name: 'RootCanalTherapy',
        component: RootCanalTherapy
    },
    {
        path: '/services/full-mouth-rehabilitations',
        name: 'FullMouthRehabilitations',
        component: FullMouthRehabilitations
    },
    {
        path: '/services/kids-dentistry',
        name: 'KidsDentistry',
        component: KidsDentistry
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: '/contact-us/thank-you',
        name: 'ContactUsThankYou',
        component: ContactUsThankYou
    },
    {
        path: '/reviews',
        name: 'Reviews',
        component: Reviews
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
