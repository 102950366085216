import DoctorsRepository from "./doctorsRepository";
import EmployeesRepository from "./employeesRepository";
import ImagesRepository from "./imagesRepository";
import OfficesRepository from "./officesRepository";
import MailRepository from "./mailRepository";
import PatientReviewsRepository from "./patientReviewsRepository";

const repositories = {
    doctors: DoctorsRepository,
    employees: EmployeesRepository,
    images: ImagesRepository,
    offices: OfficesRepository,
    mail: MailRepository,
    patientReviews: PatientReviewsRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
