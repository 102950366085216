<template>
    <div id="home-carousel">

        <vueper-slides :dragging-distance="50" :fixedHeight="computedCarouselHeight" autoplay :pause-on-hover="pauseOnHover" :transition-speed="1000" :duration="6000">
            <vueper-slide :image="require('@/assets/images/home/home-carousel-0-large.jpg')">
                <template #content>
                    <div class="home-image-text one"> <a href="/contact-us" class="home-link"><span class="hl-title">WE HAVE<br />MOVED!</span><br /><span class="hl-sub-title">For your next visit,<br /> please come to our new address at: <br /><br />9015 E Valleyway<br /> Spokane Valley, WA 99212</span></a><RequestAppointment /> </div>
                </template>
            </vueper-slide>
            <vueper-slide :image="require('@/assets/images/home/home-carousel-4-large.jpg')">
                <template #content>
                    <div class="home-image-text one"> <a href="/services/implants" class="home-link"><span class="hl-title">IMPLANT<br />DENTISTRY</span><br /><span class="hl-sub-title">Get your smile back.</span></a> <RequestAppointment /></div>
                </template>
            </vueper-slide>
            <vueper-slide :image="require('@/assets/images/home/home-carousel-2-large.jpg')">
                <template #content>
                    <div class="home-image-text two"> <a href="/services/cosmetics" class="home-link"><span class="hl-title">COSMETIC<br />DENTISTRY</span><br /><span class="hl-sub-title">Show off your new smile.</span></a> <RequestAppointment /></div>
                </template>
            </vueper-slide>
            <vueper-slide :image="require('@/assets/images/home/home-carousel-3-large.jpg')">
                <template #content>
                    <div class="home-image-text three"><a href="/services/full-mouth-rehabilitations" class="home-link"><span class="hl-title">FULL ARCH<br />REHABILITATION</span><br /><span class="hl-sub-title">Forget removable, go fixed!</span></a><RequestAppointment /></div>
                </template>
            </vueper-slide>
        </vueper-slides>


    </div>
</template>

<script>
    /*import { Carousel, Slide } from 'vue-carousel';*/
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import RequestAppointment from '@/components/offices/RequestAppointment.vue'

    // https://learnvue.co/2020/04/a-beautiful-parallax-scrolling-effect-in-vuejs-daily-vue-tips-2/
    export default {
        name: 'Home-Carousel',
        data() {
            return {
                isLoading: false,
                pauseOnHover: true,
            }
        },
        components: {
            RequestAppointment, VueperSlides, VueperSlide
        },
        created() {
            this.fetch()
        },
        methods: {
            async fetch() {
                this.isLoading = true;
                this.isLoading = false;
            }

        },
        computed: {
            isLandscape: function () { return (this.$screen.height / this.$screen.width) < 1 },
            computedCarouselHeight: function () {
                var items = (this.$screen.width < 480) ? '60vh' : (this.$screen.width < 1080) ? '70vh' : '80vh';

                if (this.isLandscape) {
                    items = (this.$screen.width < 480) ? '50vh' : (this.$screen.width < 1080) ? '55vh' : '80vh';
                }

                return items;
            },

        }
    }
</script>
<style>

    .vueperslide__image {
        opacity: .9
    }
  

    #home-carousel {
        position: relative;
    }

    .home-link, .home-link:hover {
        text-decoration: inherit; /* no underline */
        color: white;
        font-weight: bolder;
        text-shadow:1px 1px 1px #333;
    }

    @media only screen and (max-width:480px) {


        .home-image-text {
            border-radius: 5px;
            padding: 1px;
        }

        .hl-sub-title {
            font-size: 14px;
        }

        .hl-title {
            font-size: 24px;
        }

        .one {
            position: absolute;
            top: 8px;
            left: 8px;
        }

        .two {
            position: absolute;
            top: 40%; /* IMPORTANT */
            left: 30%; /* IMPORTANT */
        }

        .three {
            position: absolute;
            bottom: 50px;
            right: 26px;
        }
    }

    @media only screen and (min-width:481px) and (max-width:1080px) {


        .home-image-text {
            border-radius: 5px;
            padding: 4px
        }

        .hl-sub-title {
            font-size: 16px;
        }

        .hl-title {
            font-size: 24px;
        }

        .one {
            position: absolute;
            top: 8px;
            left: 26px;
        }

        .two {
            position: absolute;
            top: 35%; /* IMPORTANT */
            left: 35%; /* IMPORTANT */
        }

        .three {
            position: absolute;
            bottom: 60px;
            right: 16px;
        }
    }

    @media only screen and (min-width:1081px) {


        .home-image-text {
            border-radius: 5px;
            padding: 5px
        }

        .hl-sub-title {
            font-size: 24px;
        }

        .hl-title {
            font-size: 36px;
        }

        .one {
            position: absolute;
            top: 8px;
            left: 26px;
        }

        .two {
            position: absolute;
            top: 35%; /* IMPORTANT */
            left: 41%; /* IMPORTANT */
        }

        .three {
            position: absolute;
            bottom: 8px;
            right: 16px;
        }
    }

    @media only screen and (max-height:480px) and (max-width:835px) {


        .home-image-text {
            border-radius: 5px;
            padding: 1px;
            opacity: .9;
        }

        .hl-sub-title {
            font-size: 14px;
        }

        .hl-title {
            font-size: 22px;
        }

        .one {
            position: absolute;
            top: 20px;
            left: 86px;
        }

        .two {
            position: absolute;
            top: 40%; /* IMPORTANT */
            left: 30%; /* IMPORTANT */
        }

        .three {
            position: absolute;
            bottom: 8px;
            right: 16px;
        }
    }
</style>