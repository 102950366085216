<template>
  <div>
    <Doctor v-bind:doctorId="1" />
  </div>
</template>

<script>
// @ is an alias to /src
import Doctor from '@/components/Doctor.vue'

export default {
    name: 'dr-grishin',
    data() {
            return {
                header: 'About Dr. Nadia Grishin',
                subHeader: 'Dr. Grishin�s passion for dentistry began at an early age of 18 when she began her dental work as a dental technician who waxed dentures for a local dental lab.'
            };
        },
  components: {
    Doctor
  },
  title: 'Prodigy Dental Arts - Dr. Nadia Grishin'
}
</script>