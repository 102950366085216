<template>
    <div class="cosmetics">
        <ServiceHeader :header="header" :subHeader="subHeader" class="service-header" />

        <p class="body-wrapper">
            We offer an array of cosmetic dentistry services from cosmetic composite veneers to full esthetic restorations using ceramic veneers and crowns. To get started, a comprehensive evaluation is very important for Dr. Grishin to create a personalized treatment plan just for you. A separate consult visit may be recommended for Dr. Grishin to sit down with you and explain to you your treatment options and answer any questions you may have.
            <br /><br />

            To begin your smile transformation, call or text <a href="tel:+15098288339">(509) 828-8339</a> to get scheduled for your initial visit.
            <br /><br />
            From Bioclear composites to porcelain veneers to crowns and bridges (conventional and implant supported), we do it all!
        </p>
        <div class="space-after"></div>
        <p class="body-wrapper">


            <b-container class="border border-filled">
                <b-row>
                    <b-col cols="12" class="images-caption">
                        {{imagesCaption}}
                    </b-col>
                    <b-col v-for="item in images" :key="item.Caption" cols="12" lg="6" sm="6" xs="6">
                        <div class="pic-caption">
                            {{item.caption}}
                        </div>
                        <b-img :src="item.image" fluid />
                    </b-col>
                </b-row>

            </b-container>

        </p>

        <p class="body-wrapper">
            <SpearVideo header="veneers" url="//patient-api.speareducation.com/dr/132076/video/486/key/330b169f2716bba23e046ae39cb9ea52d62b1668b69cc39ff6429c50e1f23c9b?autoplay=1" />
        </p>


    </div>
</template>
<script>
    import SpearVideo from '@/components/SpearVideo.vue'
    import ServiceHeader from '@/components/services/ServiceHeader.vue'

    export default {
        name: 'cosmetics',
        data() {
            return {
                header: 'Cosmetic Dentistry',
                subHeader: 'We offer a variety of cosmetic services to improve the appearance and function of your teeth.',
                imagesCaption: "Full upper rehab using ceramic veneers and crowns",
                images: [
                    { caption: "Before", image: require('@/assets/images/services/cosmetic/cosmetics-before.jpg') },
                    { caption: "After", image: require('@/assets/images/services/cosmetic/cosmetics-after.jpg') },
                    { caption: "Front view", image: require('@/assets/images/services/cosmetic/cosmetics-teeth-1.jpg') },
                    { caption: "Palatal view", image: require('@/assets/images/services/cosmetic/cosmetics-teeth-2.jpg') }
                ]
            };
        },
        components: {
            SpearVideo,
            ServiceHeader
        },
        title: 'Prodigy Dental Arts - Cosmetic Dentistry'
    }
</script>