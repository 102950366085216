<template>
    <div class="kids-dentistry">
        <ServiceHeader :header="header" :subHeader="subHeader" class="service-header" />
        <p class="body-wrapper" style="margin-top:15px">

            <b-container v-if="!isLoading" >

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        We provide a variety of services for children from preventive to restorative as well as some orthodontics. Dr. Grishin encourages parents to bring start bringing their children to us as soon as their first baby tooth erupts. This allows us to provide preventive treatment, education, as well early minimal treatment before more extensive treatment is needed.
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-img :src="require('@/assets/images/services/kids-dentistry/kid-dentist.jpg')" fluid />
                    </b-col>
                </b-row>
            </b-container>
            <br />
            <br />
            <br />

            <spear-video header="The benefits of covering teeth with sealants" url="//patient-api.speareducation.com/dr/132076/video/860/key/df76b45915b34e5dc4fc8a93d6b642eb984909050ac3db57fcb797982ed214d0?autoplay=1" />
<p />
    </div>
</template>
<script>
import ServiceHeader from '@/components/services/ServiceHeader.vue'
import SpearVideo from '../../components/SpearVideo.vue';

export default {
  name: 'kids-dentistry',
   data () {
        return {
            header: "Kid's Dentistry",
            subHeader: 'We absolutely enjoy seeing children at our office!',
        };
    },
    components: {
      ServiceHeader,
        SpearVideo
        },
        title: 'Prodigy Dental Arts - Kid\'s Dentistry'
}
</script>