<template>
    <div class="footer">
        <b-card-footer>

            <b-container>
                <b-row>

                    <b-col align=left lg="3">
                        <div class="pda-footer-header">  HOURS </div>
                        <b-row>
                            <b-col>
                                <office-hours v-bind:officeId="1" />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col align=left lg="3">
                        <div class="pda-footer-header">  SERVICES </div>
                        <b-row>
                            <b-col>
                                <b-link href="/services/preventive-care" class="services-link">Preventive Care</b-link>
                                <br> <b-link href="/services/laser-dentistry" class="services-link">Laser Dentistry</b-link>
                                <br> <b-link href="/services/cosmetics" class="services-link">Cosmetics</b-link>
                                <br> <b-link href="/services/clear-braces" class="services-link">Clear Braces</b-link>
                                <br>   <b-link href="/services/implants" class="services-link">Implants</b-link>
                                <br>   <b-link href="/services/extractions" class="services-link">Extractions</b-link>
                                <br>   <b-link href="/services/root-canal-therapy" class="services-link">Root Canal Therapy</b-link>
                                <br>   <b-link href="/services/full-mouth-rehabilitations" class="services-link">Full Mouth Rehabilitations</b-link>
                                <br>   <b-link href="/services/kids-dentistry" class="services-link">Kid's Dentistry</b-link>

                            </b-col>
                        </b-row>

                    </b-col>
                    <b-col align=left lg="3">
                        <div class="pda-footer-header">  ADDRESS </div>

                        <b-row>
                            <b-col>
                                9015 E Valleyway Ave
                                <br />Spokane Valley, WA 99212
                                <br />
                                <br /> <b-link href="mailto:info@prodigydentalarts.com">info@prodigydentalarts.com</b-link>
                                <br />  <b-link href="tel:+15097037397">(509) 703-7397 (Office)</b-link>
                                <br /> <b-link href="tel:+15098288339">(509) 828-8339 (Text)</b-link>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col align=left lg="3">
                        <div class="pda-footer-header">  FOLLOW US </div>
                        <b-row>
                            <b-col>
                                <a href="https://www.facebook.com/nadiaspokanedentist/" target="blank" class="icon">  <b-icon-facebook style="margin-right:5px"></b-icon-facebook></a>
                                <a href="https://www.instagram.com/prodigydentalarts" target="blank" class="icon"> <b-icon-instagram style="margin-right:5px"></b-icon-instagram></a>
                                <a href="https://www.linkedin.com/in/nadia-grishin-b8004280/" target="blank" class="icon"><b-icon-linkedin></b-icon-linkedin></a>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>



                <b-row>
                    <b-col cols="12">

                        <office-map />

                    </b-col>
                    <b-col cols="12" style="text-align:center">
                        &#169; Prodigy Dental Arts 2021


                    </b-col>
                </b-row>

            </b-container>



        </b-card-footer>
    </div>
</template>

<script>
    import OfficeHours from './offices/OfficeHours.vue'
    import OfficeMap from './offices/OfficeMap.vue'
    import { BIconFacebook, BIconInstagram, BIconLinkedin } from 'bootstrap-vue'

    export default {
        components: { OfficeMap, OfficeHours, BIconFacebook, BIconInstagram, BIconLinkedin },
        name: 'pda-footer'
    }
</script>

<style>
    .icon {
        zoom: 140%;
    }

    .footer {
        margin-top: 10px;
        margin-left: 10%;
        margin-right: 10%;
    }

    .card-footer {
        border-radius: 4px 4px !important;
    }

    .services-link {
        font-size: 12px;
    }

    .pda-footer-header {
        font-weight: bold;
        margin-bottom: 5px;
    }

    @media only screen and (max-width:480px) {
        .pda-footer-header {
            margin-top: 15px;
        }
    }
</style>
