<template>
    <div class="contact-us-thank-you">
        <p class="body-wrapper">

        <p class="body-wrapper">
            Thank you for contacting Prodigy Dental Arts.  One of our team members will contact you shortly.
        </p>
        <p class="body-wrapper">
            You may also reach us by calling <a href="tel:+15097037397">(509) 703-7397</a>.
        </p>

        <b-img :src="require('@/assets/images/contact-us/thank-you.jpg')" fluid />

    </div>
</template>

<script>
    export default {
        name: 'contact-us-thank-you',
        title: 'Prodigy Dental Arts - Thank You!'
    }
</script>