<template>
<b-container v-if="!isLoading" >

<b-row  align=left>
              <b-col style="font-size:.75rem">
                 
               <b-row v-for="item in computedHours" :key="item.dayOfWeek">
                    <b-col >
                        {{item.dayName}}
                    </b-col>
                     <b-col align=left >
                        {{item.hoursFormatted}}
                    </b-col>
                </b-row>          
            </b-col>
</b-row>   
</b-container>
</template>

<script>
// @ is an alias to /src
import {RepositoryFactory} from '../../repositories/RepositoryFactory'
const OfficeRepository = RepositoryFactory.get('offices')

export default {
  name: 'office-hours',
  props: {
        officeId: {
        type: Number,
        },
        header: {
          type: String,
        },
        noheader:  {
          type: Boolean,
        },
    },
    data () {
        return {
            isLoading:false,
            officeResponse: null
        };
    },
    created() {
        this.fetch()
    },
  methods: {
    async fetch () {
      this.isLoading = true
      const { data } = await OfficeRepository.get(this.officeId)
      this.isLoading = false
      this.officeResponse = data;
    }
  },
    computed: {
    computedHours: function() { return this.officeResponse.officeHours }
    }
};
</script>
 