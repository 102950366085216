<template>
    <div class="root-canal-therapy">

        <ServiceHeader :header="header" :subHeader="subHeader" />
        <p class="body-wrapper">
            Root canal therapy dates back to 2nd or 3rd century BC. This procedure  was probably created by the Romans due to the fact they invented removable appliances and crowns. It wasn't until the 18th century that dental anethesia was discovered  and allowed for this procedure to be more comfortable and predictable.
            <br /><br />

            At Prodigy, root canal therapy is no more painful than a simple filling! By using advanced rotary technology, 3D imaging and thorough planning we are able to provide this pain and infection eliminating treatment for most teeth. To get started, Dr. Grishin will do diagnostic tests and imaging to determine the cause of the infection and discuss with your treatment options. Determining the prognosis of a particular infected tooth is very important in deciding on whether root canal therapy or implant therapy are best options long term.

        </p>

        <br />
        <br />
        <br />
        <p class="body-wrapper">

            <spear-video header="Root canal therapy" url="//patient-api.speareducation.com/dr/132076/video/524/key/6c9f80af5bb9787c63ea29f9abea40741bd9936115fee385313a40d6985ce561?autoplay=1" />


            <spear-video header="Root canal retreatment" url="//patient-api.speareducation.com/dr/132076/video/798/key/a7cc7a6e6f93c65f16a557da3e0ea9c233cfeca13d3f0afa8e65501398c2af09?autoplay=0" />
        <p />



    </div>
</template>

<script>
    // @ is an alias to /src
    import ServiceHeader from '@/components/services/ServiceHeader.vue'
    import SpearVideo from '../../components/SpearVideo.vue';

    export default {
        name: 'root-canal-therapy',
        data() {
            return {
                header: "Root Canal Therapy",
                subHeader: 'Root canal therapy is designed to help you keep your tooth for years to come!',
            };
        },
        components: {
            ServiceHeader,
            SpearVideo
        },
        title: 'Prodigy Dental Arts - Root Canal Therapy'
    }
</script>
