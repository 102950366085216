<template>
    <b-container>
        <b-row>
            <b-col>
                <b-form-rating id="rating-inline" inline :value="reviewRating" color="#ff8800" size="lg" no-border readonly></b-form-rating>

            </b-col>

        </b-row>
        <b-row>
            <b-col>
                {{reviewRating}} out of {{ratingMaxValue}} stars

            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <br />
                {{author}}
            </b-col>
        </b-row>
        <!--<b-row>
            <b-col>
                TODO: date
            </b-col>
        </b-row>-->
        <b-row>
            <b-col align="left" style="padding-top:5px">
                {{reviewBody}}
            </b-col>
        </b-row>
        <b-row>
            <b-col style="padding-top:5px">
                <b-img :src="require('@/assets/images/reviews/google.png')" fluid style="max-width:300px" />
            </b-col>
        </b-row>

    </b-container>

</template>

<script>


    export default {
        name: 'google-review',
        props: {
            author: {
                type: String,
            },
            reviewBody: {
                type: String,
            },
            reviewRating: {
                type: Number,
            },
            ratingMaxValue: {
                type: Number,
            },
        }
    };

</script>