<template>
    <div class="spear-video">

        <!--Spear Video Placeholder-->
        <p class="spear-header"> Learn about {{header}}</p>

        <iframe referrerpolicy='no-referrer-when-downgrade' :src="url" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling='no' frameborder='no' width='100%' height='100%' id="i-spear">
        </iframe>



    </div>
</template>
<style type="text/css">
    @media only screen and (max-width:767px) {
        #i-spear {
            position: relative;
            max-height: 302px;
            max-width: 540px;
            overflow: hidden;
        }
    }

    @media only screen and (min-width:768px) {
        #i-spear {
            position: relative;
            height: 302px;
            max-width: 540px;
            overflow: hidden;
        }
    }


    .spear-header {
        padding: 2px 6px 4px 6px;
        color: #212529;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: #e9ecef;
        border: #dddddd 2px solid;
        text-transform: uppercase;
        font-weight: 500;
    }

    .spear-video {
        max-width: 570px;
        background-color: #e9ecef;
        padding: 15px 15px 15px 15px;
        border-radius: 4px 4px;
        margin-bottom: 25px;
    }
</style>


<script>
    // @ is an alias to /src

    export default {
        name: 'spear-video',
        props: {
            header: {
                type: String
            },
            url: {
                type: String
            }
        }
    }
</script>
