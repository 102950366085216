import Repository from "./Repository";

const resource = "/mail";
export default {

    send(payLoad) {

        Repository.post(`${resource}/send`, payLoad)
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });


    }
};

