import Repository from "./Repository";

const resource = "/images";
export default {

    getImage(fileName, maxWidth, maxHeight) {
        return Repository.get(`${resource}/image?fileName=${fileName}&maxWidth=${maxWidth}&maxHeight=${maxHeight}`);
    },

    getLogo() {
        return Repository.get(`${resource}/logo`);
    }
};
