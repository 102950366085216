<template>
    <div class="full-mouth-rehabilitations">
        <ServiceHeader :header="header" :subHeader="subHeader" id="service-header" />
        <p class="body-wrapper">
            We understand that life circumstances can get the best of our oral health. If you find yourself in this predicament, all is not lost and NO, removable teeth (dentures) are not your best option. Patients who have lost many or all of their teeth often report that simple daily activities like kissing your spouse, talking, and eating can become burdensome when wearing removable bulky dentures.
        </p>

        <p class="body-wrapper">

            With the use of advanced protocols and technology, Prodigy Dental Arts provides an array of implant supported bridge options for patients who are losing or have already lost their teeth. Partnering with the best implant company, the most experienced dental lab, and the finest of specialists, you can feel confident that your smile is in the right hands.
        </p>

        <p class="body-wrapper">
            If you are ready to discuss the smile of your dreams, call our office to schedule a consult to find out more about your treatment options.

            <ul>
                <li>All on 4</li>
                <li>All on X</li>
                <li>Revitalize</li>
                <li>Teeth in a day</li>
                <li>Teeth in an hour</li>
                <li>Pro arch</li>
                <li>Proarch</li>
            </ul>

        </p>
              <p class="body-wrapper">
                 
                 
                  <b-container>
                      <b-row>
                          <b-col>
                              <b-img :src="require('@/assets/images/services/full-mouth-rehabilitation/full-mouth-implant-rehab.jpg')" fluid  />
                              <br /><br />
                          </b-col>
                          <b-col>
                              <b-img :src="require('@/assets/images/services/full-mouth-rehabilitation/full-mouth-implant-rehab-after.jpg')" fluid />
                              
                          </b-col>
                      </b-row>
                  </b-container>
               </p>
    </div>
</template>
<script>
import ServiceHeader from '@/components/services/ServiceHeader.vue'

export default {
        name: 'full-mouth-rehabilitations',
    data () {
        return {
            header: "Full Mouth Rehabilitations",
            subHeader: 'Discover your best smile in one day!',
        };
    },
    components: {
      ServiceHeader
        },
        title: 'Prodigy Dental Arts - Full Mouth Rehabilitations'
}
</script>