<template>
    <div id="app">
        <Navbar />

        <router-view />
        <PDAFooter class="body" />

    </div>
</template>
<style>
    @import './assets/styles/prodigy.css';
</style>
<style type="text/css">
    .header-wrapper {
        background-color: #ddf7f8;
        margin-top: !important 0px;
        margin-bottom: !important 0px;
    }
</style>


<script>
    import Navbar from './components/Navbar.vue';
    import PDAFooter from './components/PDAFooter.vue';

    export default {
        name: 'app',
        components: {
            Navbar,
            PDAFooter
        },
        title: 'Prodigy Dental Arts'
    }
</script>


