<template>
    <div>
        <HomeCarousel />
        <div>

            <b-container fluid class="top-services">
                <b-row>
                    <b-col>
                        <a href="/services/implants" class="top-service-link">
                            <b-jumbotron align=left header="Implant Dentistry" lead="Get your smile back by replacing your missing teeth." class="service-header">  </b-jumbotron>
                        </a>
                    </b-col>
                    <b-col>
                        <a href="/services/cosmetics" class="top-service-link">
                            <b-jumbotron align=left header="Cosmetic Dentistry" lead="Show off your new smile." class="service-header">  </b-jumbotron>
                        </a>
                    </b-col>
                    <b-col>
                        <a href="/services/full-mouth-rehabilitations" class="top-service-link">
                            <b-jumbotron align=left header="Full Arch Rehabilitation" lead="Forget removable, go fixed!" class="service-header" id="full-mouth-rehab-service-header">  </b-jumbotron>
                        </a>
                    </b-col>
                </b-row>
            </b-container>

            <vueper-slides :parallax="1" :parallax-fixed-content="true" :fixedHeight="computedParralaxHeight">
                <vueper-slide :image="require('@/assets/images/home/parralax.jpg')">
                    <template #content>
                        <p class="parralax-header">WELCOME TO<br>PRODIGY DENTAL ARTS! </p>
                        <div style="border-top:5px solid white; width:50%"></div>
                        <p class="parralax-details">Thank you for selecting our office for your dental care needs.<br /> We are committed to providing our community with the highest quality of dental care in a pleasant and professional manner and look forward to meeting you. </p>
                        <a href="/about/dr-grishin">
                            <!--<b-button>Learn More</b-button>-->
                        </a>
                    </template>
                </vueper-slide>
            </vueper-slides>



            <div class="home-contact-form-wrapper">

                <b-container>
                    <b-row>


                        <b-col lg="6" md="6" sm="12" xs="12">
                            <h3>Quality dentistry for the whole family!</h3>
                            We are a family dental practice focused on providing high quality, personalized dental care to our patients.  We pride ourselves on delivering evidence based dentisty, while integrating advanced technology into our practice.

                            <vueper-slides :fixedHeight="computedContactUsHeight" style="margin-top:7px">
                                <vueper-slide :image="require('@/assets/images/home/contact.jpg')" style="border-radius:5px;">


                                </vueper-slide>
                            </vueper-slides>
                            <br />
                        </b-col>
                        <!--</b-row>
                        <b-row>-->
                        <b-col lg="6" md="6" sm="12" xs="12">
                            <h3>We are excited to take care of your smile!</h3>
                            <h5 style="font-weight:300">Experience the difference of a locally owned business; make an appointment today.</h5>
                            <!--<h4>Experience the difference different type of dentistry, make an appointment to today.</h4>-->
                            <ContactForm class="home-contact-form"></ContactForm>
                        </b-col>



                    </b-row>
                </b-container>

            </div>
            <br />
            <ReviewCarousel />
        </div>
    </div>
</template>

<style>
    /*  .vueperslides--fixed-height {
        height: 500px;
    }*/
    .parralax-header {
        color: white;
        font-size: 3vw;
        text-align: center;
        font-weight: bold;
        vertical-align: top;
        text-shadow: 1px 1px 1px #333;
    }

    .parralax-details {
        color: white;
        text-align: center;
        padding-top: 15px;
        font-size: 2vh;
        text-shadow: 1px 1px 1px #333;
    }

    @media only screen and (max-width:500px) {
        #full-mouth-rehab-service-header .display-3 {
            font-size: 13vw;
        }
    }

    .top-service-link, .top-service-link:hover {
        color: inherit; /* blue colors for links too */
        text-decoration: inherit; /* no underline */
    }

    .top-services {
        padding-top: 30px;
        background-color: #AAAAAA;
    }

    .home-contact-form {
        border: 1px solid #7FB1E3;
        padding: 15px;
        border-radius: 4px 4px;
    }

    .home-contact-form-wrapper {
        /*  width: 100%;*/
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }
</style>

<script>
    import ContactForm from '@/components/offices/ContactForm.vue'
    import HomeCarousel from '@/components/HomeCarousel.vue'
    import ReviewCarousel from '@/components/reviews/ReviewCarousel.vue';
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

    // https://learnvue.co/2020/04/a-beautiful-parallax-scrolling-effect-in-vuejs-daily-vue-tips-2/
    export default {
        name: 'Home',
        components: {
            HomeCarousel, ContactForm, ReviewCarousel, VueperSlides, VueperSlide
        },
        computed: {
            computedParralaxHeight: function () {
                var items = (this.$screen.width / 12) + 'vh'
                //(this.$screen.width < 480) ? '30vh' : (this.$screen.width < 1080) ? '70vh' : '80vh';

                if (this.isLandscape) {
                    items = (this.$screen.width / 9.6) + 'vh'
                    //(this.$screen.width < 480) ? '50vh' : (this.$screen.width < 1080) ? '55vh' : '80vh';
                }

                return items;
            },
            computedContactUsHeight: function () {
                return (this.$screen.width / 18) + 'vh';
            }

        },
        title: 'Prodigy Dental Arts'
    }
</script>
