//src/components/TeamMembers.vue
<template>
    <b-container v-if="!isLoading">

        <b-row v-for="item in computedEmployees" :key="item.employeeId">
            <b-col lg="12">
                <h3>Our Team</h3>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col>


                                <b-img :src="`data:image/png;base64,${item.base64Image}`" :alt="`${item.firstName} ${item.lastName}`" fluid width="250" />

                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="8" lg="8" align=left>
                        <b-row>
                            <b-col>
                                {{item.title}}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                {{item.firstName}} {{item.lastName}}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                {{item.biography}}
                            </b-col>
                        </b-row>

                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>


</template>

<script>
    import { RepositoryFactory } from './../repositories/RepositoryFactory'
    const EmployeesRepository = RepositoryFactory.get('employees')

    export default {
        name: 'team-members',
        data() {
            return {
                isLoading: false,
                employeeResponse: null,
            };
        },
        created() {
            this.fetch()
        },
        methods: {
            async fetch() {
                this.isLoading = true
                const { data } = await EmployeesRepository.get()
                this.isLoading = false
                this.employeeResponse = data;
            }
        },
        computed: {
            computedEmployees: function () { return this.employeeResponse.employees }

        }

    };
</script>
<style scoped>
    .bv-example-row {
        border: 1px solid black;
    }
</style>