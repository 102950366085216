<template>
    <div class="implants">
        <ServiceHeader :header="header" :subHeader="subHeader" />

        <p class="body-wrapper">
            Prodigy Dental Arts offers an array of restorative and surgical implant services. With the implementation of advanced surgical techniques and technology, our implant surgeries are pre-planned using 3D imaging (cone beam CT) and advanced Straumann surgical software. Our implant components and restorations are authentic and are made locally in Spokane, WA.  To achieve a superior functional and esthetic result, we provide superior implant positioning by using guided surgery and immediate soft tissue contouring for most surgeries.
         </p>
        <div class="body-wrapper border border-filled" style="max-width:330px">
            <div class="images-caption">
               Surgery
            </div>
            <b-img :src="require('@/assets/images/services/implants/implants-7-surgical-photo.jpg')" fluid />
        </div>
            <p class="body-wrapper">
                <br />
                Some of the implant services we provide are:
                <br />
                <ul>
                    <li>Implants replacing single missing teeth</li>
                    <li>Implant bridges to replace multiple missing teeth</li>
                    <li>Full arch and/or full mouth implant rehabilitation to replace all missing teeth</li>
                </ul>
            </p>
      
        <p class="body-wrapper">


            <b-container class="border border-filled">
                <b-row>
                    <b-col cols="12" class="images-caption">
                        {{imagesCaption}}
                    </b-col>
                    <b-col v-for="item in images" :key="item.Caption" cols="12" lg="6" sm="6" md="6" xs="6">
                        <div class="pic-caption" style="height:117px">
                            {{item.caption}}
                        </div>
                        <b-img :src="item.image" fluid  />
                    </b-col>
                </b-row>

            </b-container>

        </p>
        <p class="body-wrapper">
            If you are interested in discussing your implant treatment options, please contact us to schedule your implant planning visit <a href="tel:+15097037397">(509) 703-7397</a>.

        </p>
        <p class="body-wrapper">

            <spear-video header="CBCT" url="//patient-api.speareducation.com/dr/132076/video/765/key/bed6db86bc08da44bd31ab8a655f5054d0c34dd9c350cc7ace0aa4088c32a62a?autoplay=1" />


            <spear-video header="Implant vs Bridge" url="//patient-api.speareducation.com/dr/132076/video/489/key/fa754e2dd8c4b2cf81c66f0c0e177e9abf8ea6668bf15a850d642131d5871dd5?autoplay=0" />
        <p />


    </div>
</template>

<script>
import ServiceHeader from '@/components/services/ServiceHeader.vue'
import SpearVideo from '../../components/SpearVideo.vue';

export default {
  name: 'implants',
     data () {
        return {
            header: "Implant Dentistry",
            subHeader: 'Having dentures or partials are no longer your only option! Find out how quick and straight forward replacing your missing tooth/teeth with a fixed implant can be.',
            imagesCaption: "Here are some highlights of our implant placement surgeries...",
            images: [
                { caption: "X-ray of a lower right failed bridge.", image: require('@/assets/images/services/implants/implants-1.jpg') },
                { caption: "Implants placed on the same days as tooth removal.", image: require('@/assets/images/services/implants/implants-2.jpg') },
                { caption: "Implant bridge planning done before surgery using 3D cone beam x-ray and surgical software.", image: require('@/assets/images/services/implants/implants-4.jpg') },
                { caption: "Implant crown for implant placed just 7 weeks earlier.", image: require('@/assets/images/services/implants/implants-3.jpg') },
               
                { caption: "Implant placed with an added component to form tissues for ideal tissue forming.", image: require('@/assets/images/services/implants/implants-5-tissue-former-8-weeks-post-surgery.jpg') },
                { caption: "Added tissue forming component removed to show ideal form of tissues underneath.  This enchances the looks, function, and health of implant tissues.", image: require('@/assets/images/services/implants/implants-6-tissue-former-out.jpg') }

            ]
        };
    },
    components: {
      ServiceHeader,
        SpearVideo
        },
        title: 'Prodigy Dental Arts - Implants'
}
</script>