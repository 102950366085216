<template>
    <div class="preventive-care">
        <ServiceHeader :header="header" :subHeader="subHeader" :details="details" class="service-header" />

        <div class="body-wrapper border border-filled" style="max-width:475px">
            <div class="images-caption">
                Prevention is the best medicine!
            </div>
            <b-img :src="require('@/assets/images/services/preventive-care/tooth-brush.jpg')" fluid />
        </div>



        <p class="body-wrapper">


            <br />      <br />
            Beginning her dental career in dental hygiene, Dr. Grishin is naturally extremely focused on prevention. As part of our prevention plan, we emphasize the importance of daily oral hygiene practices as well as professional prophylactic services performed by our highly trained and dedicated hygienists.
            <br />
            <br />
            Prevention services we provide:
            <br />
            <ul>
                <li>Prophylaxis (teeth cleaning)</li>
                <li>Periodontal maintenance (cleaning after history of gum disease)</li>
                <li>Periodontal diagnostic charting</li>
                <li>Fluoride treatments</li>
                <li>Scaling and root planing</li>
                <li>Laser Bacterial Reduction</li>
                <li>Laser aphthous ulcer treatment</li>
                <li>Laser cold sore treatment</li>
                <li>Oral hygiene instruction</li>
            </ul>


        </p>
        <br />

        <p class="body-wrapper">

            <spear-video header="Prophy vs. Periodontal Maintenance" url="//patient-api.speareducation.com/dr/132076/video/790/key/1a0eb30a3623bb52c0a7b89565fbf69f024fd79fc8d342106050f315d2eb9723?autoplay=1" />

            <span class="space-after"></span>
            <spear-video header="Prophy vs. Scaling and Root Planing" url="//patient-api.speareducation.com/dr/132076/video/793/key/54775218b4e87095d84e550046a999a892117aff5ed0eca3f5b223968c999711?autoplay=0" />


        </p>
    </div>


</template>

<script>
    import ServiceHeader from '@/components/services/ServiceHeader.vue'
    import SpearVideo from '../../components/SpearVideo.vue';

    export default {
        name: 'preventive-care',
        data() {
            return {
                header: 'Preventive Care',
                subHeader: 'We encourage people to take control of their oral health by stressing preventive care to our patients.',
            };
        },
        components: {
            SpearVideo,
            ServiceHeader
        },
        title: 'Prodigy Dental Arts - Preventive Care'
    }
</script>

