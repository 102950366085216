//src/main.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueScreen from 'vue-screen';
import App from './App.vue'

//bootstrap vue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//social media


//router
import router from './router'

//filters
import './filters'

//mixins
import titleMixin from './mixins/titleMixin'


Vue.mixin(titleMixin)
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueScreen);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')