<template>
  <div>
    
    <TeamMembers />
  </div>
</template>

<script>
// @ is an alias to /src
import TeamMembers from '@/components/TeamMembers.vue'

export default {
  name: 'our-team',
  components: {
    TeamMembers
  }
}
</script>