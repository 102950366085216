<template>
    <div class="service-header">
          <b-jumbotron align=left :header="header" :lead="subHeader" >
     <span>
    <RequestAppointment />
    </span>
  </b-jumbotron>
    </div>
</template>

<style type="text/css">
    .service-header {
        margin-bottom:25px;
    }
</style>


<script>
import RequestAppointment from '@/components/offices/RequestAppointment.vue'

export default {
  name: 'service-header',
  props: {
        header: {
        type: String,
        },
                subHeader: {
        type: String,
        },
                details: {
        type: String,
        }
    },
    components: {
      RequestAppointment
    }
   
}
</script>