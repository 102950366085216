<template>
    <div>

        <!--<b-container v-if="!isLoading">
        <b-row>
            <b-col>
                {{statistics.averageRating}} Out of 5 Stars

            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-rating id="rating-inline" inline :value="statistics.averageRating" color="#ff8800" size="lg" no-border readonly></b-form-rating>

            </b-col>
        </b-row>

        <b-row>
            <b-col>
                Overall rating of {{statistics.totalReviews}} 1st-party reviews

            </b-col>
        </b-row>
    </b-container>-->
        <b-container>
            <b-row>
                <b-col>
                    <h3>
                        See what some of our wonderful patients have said about us...
                    </h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <carousel :per-page="computedItemsPerPage" autoplay loop autoplayHoverPause paginationColor="#17A2B8" paginationPosition="bottom" :autoplayTimeout="20000" :speed="2000">
                        <slide v-for="item in patientReviewsResponse" :key="item.Author">
                            <div class="slide">
                                <GoogleReview v-bind:author="item.author" v-bind:reviewBody="item.reviewBody" v-bind:reviewRating="item.reviewRating" v-bind:ratingMaxValue="item.ratingMaxValue" />
                            </div>
                        </slide>
                        <slide>
                            <div class="slide">
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjacobsglass%2Fposts%2Fpfbid0GBspCXf6rGM7DgMcQDUfe4BxUmwBZ2CSRk9dfWdGpjYKjU3BcrYCQACmfC8XMrSzl&show_text=true&width=365" width="365" height="282" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>
                        </slide>

                        <slide>
                            <div class="slide">
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmaryanne.oleynik%2Fposts%2F10157734598470233&show_text=true&width=365" width="365" height="244" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>
                        </slide>

                        <slide>
                            <div class="slide">
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fandrea.kauffman.16%2Fposts%2F10224601142675684&show_text=true&width=365" width="365" height="282" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>
                        </slide>



                    </carousel>
                </b-col>
            </b-row>
        </b-container>
</div>
</template>

<style type="text/css">
    .slide {
        border: 1px solid #B4B3B8;
        margin: 2px;
        height: 700px;
        border-radius: 4px;
    }
</style>

<script>
    import { Carousel, Slide } from 'vue-carousel';
    import GoogleReview from '@/components/reviews/GoogleReview.vue'
    import { RepositoryFactory } from './../../repositories/RepositoryFactory'
    const PatientReviewsRepository = RepositoryFactory.get('patientReviews')

    export default {
        name: 'review-carousel',
        data() {
            return {
                isLoading: false,
                patientReviewsResponse: null,
            }
        },
        components: {
            GoogleReview,
            Carousel,
            Slide
        },
        created() {
            this.fetch()
        },
        methods: {
            async fetch() {
                this.isLoading = true;
                const { data } = await PatientReviewsRepository.get();
                this.patientReviewsResponse = data;
                //const { stats } = await PatientReviewsRepository.statistics();
                //this.statistics = stats;
                this.isLoading = false;
            }
        },
        computed: {
            computedItemsPerPage: function () {
                var items = (this.$screen.width < 550) ? 1 : (this.$screen.width < 1200) ? 2 : 3; 
                return items;
            }

        }
    }
</script>   