<template>
    <div class="laser-dentistry">
        <ServiceHeader :header="header" :subHeader="subHeader" class="service-header" />
        <p class="body-wrapper">
            We are proud to provide laser dentistry in our office. By using this technology in preventive, restorative, and surgical procedures, we are able to speed up the healing process through bio-stimulation of the cells involved. This benefits our patients by decreasing post-operative pain and swelling.
            <br /><br />
            Our laser accelerates healing through:
            <ul>
                <li>Enhancing blood circulation</li>
                <li>Enhancing collagen formation</li>
                <li>Increasing osteoblastic and fibroblastic activity (these are the cells that form tissue and bone in your body)</li>
            </ul>

            <br />
            Laser assisted procedures we provide:
            <ul>
                <li>Frenectomy</li>
                <li>Gingivectomy</li>
                <li>Laser Assisted Periodontal Therapy</li>
                <li>Laser Bacterial Reduction</li>
                <li>Laser cold sore treatment</li>
                <li>Laser aphthous ulcer treatment</li>
                <li>Laser tooth desensitization</li>
            </ul>


            <b-img :src="require('@/assets/images/services/laser-dentistry/diode-laser.jpg')" fluid  />

        </p>
        <br /><br />
        <p class="body-wrapper">
            
            <spear-video header="Laser gingivectomy" url="//patient-api.speareducation.com/dr/132076/video/892/key/6e7bc6ff26f655d6655c0e420cc25283d0d28ffe88ab33734fb496d953ffcffd?autoplay=1" />

            
            <spear-video header="Laser gingivectomy (esthetic)" url="//patient-api.speareducation.com/dr/132076/video/891/key/30ece980baf268401ce459f46c09ba42cedc9150be961c3ca45d7a605c42ecbb?autoplay=0" />
        </p>
    </div>
</template>
<script>
    import ServiceHeader from '@/components/services/ServiceHeader.vue'
    import SpearVideo from '../../components/SpearVideo.vue';
 

    export default {
        name: 'implants',
        data() {
            return {
                header: "Laser Dentistry",
                subHeader: 'Lasers are imperative to accelerate healing and minimize post surgery pain and swelling.',
            };
        },
        components: {
            ServiceHeader,
            SpearVideo
        },
        title: 'Prodigy Dental Arts - Laser Dentistry'
    }
</script>