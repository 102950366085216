<template>
    <div>
        <b-button variant="primary" v-b-modal.modal-request-appointment onclick="initFlexBook('https://flexbook.me/prodigy')">Request Appointment</b-button>
    </div>
</template>

 
<script>
    // @ is an alias to /src
 

    export default {
        name: 'request-appointment',
        data() {
            return {

            }
        }
    }

     
</script>