<template>
<li class="list-group-item">
    <div class="google-maps">
        <iframe src="https://maps.google.com/maps?hl=en&amp;q=9015%20E%20Valleyway%20Ave%20Spokane%20Valley,%20WA%2099212+(Prodigy%20Dental%20Arts)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
</li>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'office-map'
}
</script>
<style type="text/css">
    .google-maps {
        position: relative;
        padding-bottom: 75%;
        overflow: hidden;
    }


        .google-maps iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
</style>