//src/components/Doctor.vue
<template>
    <div class="body-wrapper" style="margin-right:auto;margin-left:auto;margin-top:15px">
        <b-container v-if="!isLoading">
            <b-row>
                <b-col lg="12">

                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col>

                                    <b-img :src="`data:image/jpg;base64,${computedDoctor.base64Image}`" fluid alt="Prodigy Dental Arts" style="max-width:350px;" />

                                </b-col>
                            </b-row>
                          
                            <b-row>
                                <b-col>
                                    Office: <a :href="computedOffice.city">{{computedOffice.city}}</a>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    Phone: <a :href="`tel:${computedOffice.phoneNumber}`">{{computedOffice.phoneNumber}}</a>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    E-mail: <a :href="`mailto:${computedOffice.emailAddress}`">{{computedOffice.emailAddress}}</a>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <RequestAppointment />
                                    <br />
                                </b-col>
                            </b-row>


                        </b-col>
                        <b-col cols="12" lg="8" xl="8" md="8" sm="12" xs="12">
                            <b-row>
                                <b-col>
                                    <h2>ABOUT {{computedDoctor.title | uppercase}} {{computedDoctor.firstName | uppercase}} {{computedDoctor.lastName | uppercase}}</h2>

                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    {{computedDoctor.background}} <br />
                                    <br />
                                </b-col>
                            </b-row>
                            <b-row v-for="item in computedSchools" :key="item.order">
                                <b-col>

                                    <h4>
                                        {{item.name}}
                                        <br>

                                        {{item.program}}
                                    </h4>
                                    <p>{{item.degree}}</p>

                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <br />
                                    <h4>Honors and Awards</h4>
                                    <ul>
                                        <li v-for="item in computedHonors" :key="item.order" :title="item.additionalDetails" style="padding-top:5px">
                                            {{item.name}}
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <br />
                                    <h4>Profesional Affiliations</h4>
                                    <ul>
                                        <li v-for="item in computedMemberships" :key="item.order" style="padding-top:5px">
                                            {{item.name}}
                                        </li>
                                    </ul>
                                    <br>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <br /><br />
                                          <p>
                                              {{this.computedDoctor.patientCare}} For more information, please contact Prodigy Dental Arts in Spokane Valley at <a href="tel:+15097037397">(509) 703-7397</a>.
                                          </p>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
 
</template>

<script>
import RequestAppointment from '@/components/offices/RequestAppointment.vue'
import {RepositoryFactory} from './../repositories/RepositoryFactory'
const DoctorsRepository = RepositoryFactory.get('doctors')

export default {
    name: 'doctor',
    props: {
        doctorId: {
        type: Number,
        }
    },
    components: {
      RequestAppointment
    },
    data () {
        return {
            isLoading:false,
            doctorResponse: null,
        };
    },
    created() {
        this.fetch()
    },
  methods: {
    async fetch () {
      this.isLoading = true
      const { data } = await DoctorsRepository.get(this.doctorId)
      this.isLoading = false
      this.doctorResponse = data;
    }
  },
    computed: {
    computedDoctor: function() { return this.doctorResponse.doctor },
    computedOffice: function() { return this.doctorResponse.office },
    computedSchools: function() { return this.computedDoctor.schools },
    computedHonors: function() { return this.computedDoctor.honors },
    computedMemberships: function() { return this.computedDoctor.memberships }
    
    }

};
</script>
<style scoped>
.bv-example-row {border:1px solid black;}
</style>