<template>
    <div>
        <ServiceHeader :header="header" :subHeader="subHeader" class="service-header" />
        <p class="body-wrapper">
            <b-container v-if="!isLoading">
                <b-row>
                    <b-col>
                        At Prodigy, we believe in providing minimally invasive care with compassion by preserving bone and tissues while removing teeth. With implementation of advanced surgical techniques and Piezotome Cube technology, we are able to provide atraumatic extractions with immediate implant placement and/or predictable healing bone grafting. Our patients are very happy to receive this type of treatment as it significantly reduces post-operative pain and swelling.

                    </b-col>
                    <b-col>
                        <b-img :src="require('@/assets/images/services/extractions/piezotome-cube.jpg')" fluid style="max-width:540px" />

                    </b-col>
                </b-row>
            </b-container>
        </p>
        <br />
        <br />
        <br />
        <p class="body-wrapper">
            <spear-video header="surgical extraction" url="//patient-api.speareducation.com/dr/132076/video/573/key/1716ce0e83605024806a4e4ba5deac346ea8166d01bfe63626e1d383c4fbd45f?autoplay=1" />
            <spear-video header="implant placement" url="//patient-api.speareducation.com/dr/132076/video/585/key/0a110fe0fe893e9f9aa22b7e5fe907f500636af2dfc2abb74ba8c7200f351481?autoplay=0" />
        <p />
    </div>
</template>
<script>
import ServiceHeader from '@/components/services/ServiceHeader.vue'
import SpearVideo from '../../components/SpearVideo.vue';
export default {
  name: 'extractions',
     data () {
        return {
            header: "Extractions",
            subHeader: ' Dr. Grishin will remove teeth when necessary. Sometimes an implant can be placed on the same day of tooth removal.',
        };
    },
    components: {
      ServiceHeader,
        SpearVideo
        },
        title: 'Prodigy Dental Arts - Extractions'
}
</script>