<template>
    <div>
        <h2>
            {{title}}
        </h2>
        Now you can schedule your appointments online! If you are a new patient or a current patient, just click "Request Appointment" and follow the prompts.

        <br /><br />
        <b-button variant="primary" v-b-modal.modal-request-appointment onclick="initFlexBook('https://flexbook.me/prodigy')">Request Appointment</b-button>

        <br /><br />
        We look forward to your visit!
    </div>
</template>
<script>
    export default {
        name: 'contact-form',
        props: {
            title: {
                default: "Contact Us", type: String
            },
        }
    }
</script>