<template>
    <div class="clear-braces">
        <ServiceHeader :header="header" :subHeader="subHeader" />
        <p class="body-wrapper">
            We provide a variety of orthodontic services including but not limited to clear aligner therapy for ortho relapse, mild and moderate crowding, retention, and orthodontic appliances for kids.
            <br />
            <br />
            <b-img :src="require('@/assets/images/services/clear-braces/clear-braces.jpg')" fluid />
        </p>
        <br />
        <br />
        <br />
        <p class="body-wrapper">
            <spear-video header="Clear Aligner Technology (Impression)" url="//patient-api.speareducation.com/dr/132076/video/539/key/ad5535d0b62a6ea6fab7abf952c2939af06f1d6d5b7b91e3af5a561f534eaf93?autoplay=1" />


        </p>

    </div>
</template>
<script>
    import ServiceHeader from '@/components/services/ServiceHeader.vue'
    import SpearVideo from '@/components/SpearVideo.vue';

    export default {
        name: 'implants',
        data() {
            return {
                header: "Clear Braces",
                subHeader: 'Some smiles can be dramatically improved with a shorter treatment plan than you expect!',
            };
        },
        components: {
            ServiceHeader,
            SpearVideo
        },
        title: 'Prodigy Dental Arts - Clear Braces'
    }
</script>