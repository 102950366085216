<template>
    <div class="contact-us" style="margin-top:15px">
        <b-container>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <p class="body-wrapper" style="margin-right: 2vw">
                        Welcome to Prodigy Dental Arts! Thank you for selecting our office for your dental care needs. We are committed to providing our community with the highest quality of dental care in a pleasant and professional manner and look forward to meeting you.
                    </p>
                    <p class="body-wrapper" style="margin-right: 2vw">
                        The first visit to Prodigy Dental Arts includes a comprehensive exam, so please plan to be at our office for an hour or more. During this visit, our goal is to thoroughly assess and diagnose your dental care needs. This appointment may include a full mouth set of x-rays, full mouth photos, impressions of your upper and lower teeth and a thorough discussion about our findings as well as what treatment our doctor recommends. In case you have extensive needs, an additional consult appointment may be required to give our doctor time to plan your case.
                    </p>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div style="border: 1px solid #7FB1E3; padding:15px; border-radius:4px 4px;max-width:540px;margin-left:auto;margin-right:auto">

                        <ContactForm></ContactForm>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ContactForm from '../components/offices/ContactForm.vue'

    export default {
        name: 'contact-us',
        components: {
            ContactForm,
        },
        title: 'Prodigy Dental Arts - Contact Us'
    }
</script>